import React from 'react'
import Error from '../pages/error/Error'
import BackgroundHome from '../assets/img/backgrounds/bg-home.webp'
import BackgroundQuoteRequests from '../assets/img/backgrounds/bg-solicitacoes.webp'
// import DetailsRegistrationForm from 
// '../pages/policy-registration/registration-form/confirmation/Details'

const Home = React.lazy(() => import('../pages/home/Home'))
const QuoteRequests = React.lazy(() => import('../pages/quote-requests/QuoteRequests'))
const CustomerBalance = React.lazy(() => import('../pages/customer-balance/CustomerBalance'))
const ManageBalance = React.lazy(() => import('../pages/manage-balance/ManageBalance'))
const ManagementX = React.lazy(() => import('../pages/management-x/ManagementX'))
const CustomerDebts = React.lazy(() => import('../pages/customer-debts/CustomerDebts'))
const Alerts = React.lazy(() => import('../pages/alerts/Alerts'))
const Policies = React.lazy(() => import('../pages/policy/Policies'))
// const PolicyAttachments = React.lazy(() => 
// import('../pages/policy-registration/policy-attachments/PolicyAttachments'))
// const RegistrationForm = React.lazy(() => 
// import('../pages/policy-registration/registration-form/RegistrationForm'))
const ManageAccess = React.lazy(() => import('../pages/manage-access/ManageAccess'))
const EditUser = React.lazy(() => import('../pages/manage-access/edit-user/EditUser'))
const CompanyRegistration = React.lazy(() => import('../pages/manage-access/company-registration/CompanyRegistration'))

const MasterRoutes = [
  {
    path: '/',
    Component: Home,
    bgImage: BackgroundHome,
  },
  { path: '/error/:status', name: 'Erro', Component: Error },
  {
    path: '/solicitacoes',
    Component: QuoteRequests,
    name: 'Solicitações',
    bgImage: BackgroundQuoteRequests,
  },
  {
    path: '/solicitacoes/:identifier/dados-gerais',
    Component: QuoteRequests,
    name: 'Solicitações',
    bgImage: BackgroundQuoteRequests,
  },
  {
    path: '/apolices',
    Component: Policies,
    name: 'Apólices',
    bgImage: BackgroundHome,
  },
  // {
  //   path: '/cadastro-de-apolices',
  //   Component: PolicyAttachments,
  //   name: 'Cadastro de Apólices',
  //   bgImage: BackgroundHome,
  // },
  // {
  //   path: '/cadastro-de-apolices/cadastro/confirmacao',
  //   Component: DetailsRegistrationForm,
  //   bgImage: BackgroundQuoteRequests,
  // },
  // {
  //   path: '/cadastro-de-apolices/cadastro',
  //   Component: RegistrationForm,
  //   name: 'Cadastro',
  //   bgImage: BackgroundHome,
  // },
  {
    path: '/alertas',
    Component: Alerts,
    name: 'Alertas',
    bgImage: BackgroundQuoteRequests,
  },
  {
    path: 'cliente/consultar-cliente',
    Component: CustomerBalance,
    name: 'Consultar Cliente',
    bgImage: BackgroundHome,
  },
  {
    path: 'cliente/gerir-balanco',
    Component: ManageBalance,
    name: 'Gerir Balanço',
    bgImage: BackgroundHome,
  },
  {
    path: 'servicos/novo-chamado',
    Component: ManagementX,
    name: 'Serviços',
    bgImage: BackgroundHome,
  },
  {
    path: 'cliente/debitos',
    Component: CustomerDebts,
    name: 'Débitos',
    bgImage: BackgroundHome,
  },
  {
    path: 'cliente/gerir-acesso',
    Component: ManageAccess,
    name: 'Gerir Acesso',
    bgImage: BackgroundHome,
  },
  {
    path: 'cliente/editar-usuario',
    Component: EditUser,
    name: 'Editar Usuário',
    bgImage: BackgroundHome,
  },
  {
    path: 'cliente/cadastro-de-empresa',
    Component: CompanyRegistration,
    name: 'Cadastro de Empresa',
    bgImage: BackgroundHome,
  },
]

export default MasterRoutes
